import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useBookingStore from "../../store/bookings";
import { useEffect, useState } from "react";
import Vars from "../../_config/vars";

const ManualPurchaseDialog = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(false);

  const {
    selectedBooking,
    openManualPurchaseDialog,
    setOpenManualPurchaseDialog,
    manualPurchase,
    newManualPurchase,
    setNewManualPurchase,
    setSnackBar,
    oldManualPurchase,
    setOldManualPurchase,
    setBookingsPending,
  } = useBookingStore((state) => ({
    selectedBooking: state.selectedBooking,
    openManualPurchaseDialog: state.openManualPurchaseDialog,
    setOpenManualPurchaseDialog: state.setOpenManualPurchaseDialog,
    manualPurchase: state.manualPurchase,
    newManualPurchase: state.newManualPurchase,
    setNewManualPurchase: state.setNewManualPurchase,
    setSnackBar: state.setSnackBar,
    oldManualPurchase: state.oldManualPurchase,
    setOldManualPurchase: state.setOldManualPurchase,
    setBookingsPending: state.setBookingsPending,
  }));

  useEffect(() => {
    const { purchases_providers } = selectedBooking.booking_handler ? selectedBooking.booking_handler : [];

    const { cart } = selectedBooking;
    const activityName = cart.items[0].activity.name;

    // @ts-ignore
    const purchase_email_config = Vars[activityName] ? Vars[activityName].emailPurchase : "";

    const provider = purchases_providers ? purchases_providers[0].payment_provider.provider : null;

    setOldManualPurchase({
      trackingNumber: purchases_providers ? purchases_providers[0].tracking_number : "",
      card: purchases_providers ? purchases_providers[0].payment_provider.credit_card.number_card : "",
      purchase_email: purchases_providers ? purchases_providers[0].purchase_email : "",
      total_paid: purchases_providers && purchases_providers[0].total_paid === null ? "" : purchases_providers[0].total_paid,
      provider: provider,
    });
    setNewManualPurchase({
      trackingNumber: purchases_providers ? purchases_providers[0].tracking_number : "",
      card: "",
      purchaseEmail: purchase_email_config ? purchase_email_config : "",
      month: "",
      year: "",
      cvv: "",
      fullName: "",
      total_paid: "",
      provider: provider ?? "qonto",
    });
  }, []);

  const handleClose = () => {
    setOpenManualPurchaseDialog(false);
  }

  const handleManualPurchase = (event: any) => {
    // update the newCustomer state with the new value from the input field 
    if (event.target.name === "total_paid") {
      setNewManualPurchase({
        ...newManualPurchase,
        [event.target.name]: event.target.value.replace(/[^0-9.]/g, ""),
      });
      return;
    }

    setNewManualPurchase({
      ...newManualPurchase,
      [event.target.name]: event.target.value,
    });
  }

  const handleSubmit = async () => {
    setLoading(true);
    const done = await manualPurchase();
    // @ts-ignore
    if (done) {
      setLoading(false);
      setSnackBar({
        open: true,
        message: "Manual purchase successfully",
        type: "success",
      });

      setTimeout(() => {
        handleClose();
        setBookingsPending();
        setSnackBar({
          open: false,
          message: "Manual purchase successfully",
          type: "success",
        });
      }
        , 2000);
      // handleClose();
    } else {
      setLoading(false);
      setSnackBar({
        open: true,
        message: "Error manual purchase",
        severity: "error",
      });

      setTimeout(() => {
        handleClose();
        setSnackBar({
          open: false,
          message: "Error manual purchase",
          severity: "error",
        });
      }
        , 2000);
    }
  }

  const open = openManualPurchaseDialog;

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Manual purchase
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Tracking number"
          name="trackingNumber"
          size="small"
          variant="standard"
          value={newManualPurchase.trackingNumber}
          onChange={handleManualPurchase}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Mail purchase"
          name="purchaseEmail"
          size="small"
          variant="standard"
          value={newManualPurchase.purchaseEmail}
          onChange={handleManualPurchase}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Card"
          name="card"
          size="small"
          variant="standard"
          value={newManualPurchase.card}
          onChange={handleManualPurchase}
          sx={{ marginBottom: 2 }}
        />
        <Box display={"flex"} gap={"3rem"}>
        <TextField
          fullWidth
          label="Month"
          name="month"
          size="small"
          variant="standard"
          value={newManualPurchase.month}
          onChange={handleManualPurchase}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Year"
          name="year"
          size="small"
          variant="standard"
          value={newManualPurchase.year}
          onChange={handleManualPurchase}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="CVV"
          name="cvv"
          size="small"
          variant="standard"
          value={newManualPurchase.cvv}
          onChange={handleManualPurchase}
          sx={{ marginBottom: 2 }}
        />
        </Box>
        <TextField
          fullWidth
          label="Full name"
          name="fullName"
          size="small"
          variant="standard"
          value={newManualPurchase.fullName}
          onChange={handleManualPurchase}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          type="text"
          label="Total paid"
          name="total_paid"
          size="small"
          variant="standard"
          value={newManualPurchase.total_paid}
          onChange={handleManualPurchase}
          sx={{ marginBottom: 2 }}
        />
        {selectedBooking.cart.items[0].venue.name === "sagrada" && (
          <TextField
            fullWidth
            label="Purchase email"
            name="purchase_email"
            size="small"
            variant="standard"
            value={newManualPurchase.purchaseEmail}
            onChange={handleManualPurchase}
            sx={{ marginBottom: 2 }}
          />
        )}
        {oldManualPurchase.provider === null && (
          <TextField
            fullWidth
            label="Provider"
            name="provider"
            size="small"
            variant="standard"            
            onChange={handleManualPurchase}
            sx={{ marginBottom: 2 }}
            value={newManualPurchase.provider}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} autoFocus variant="contained" color="primary"
          disabled={newManualPurchase.trackingNumber === "" || newManualPurchase.card === "" || loading }
        >
          Manual
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ManualPurchaseDialog;