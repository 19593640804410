import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { useEffect, useState } from "react"
import useBookingStore from "../../store/bookings"
import { useRemoveFile } from "../../hooks/useRemoveFile"

interface Props {
    fileType: string,
    fileManagement: any,
    setFileManagement: any
}

export const RefundFileDialog = ({ fileType, fileManagement, setFileManagement }: Props) => {

    const { openRemoveFileDialog, setOpenRemoveFileDialog, selectedBooking, getFileManagement } = useBookingStore();
    const { loading, removeFile } = useRemoveFile();

    const handleClose = () => {
        setOpenRemoveFileDialog(false)
    }

    const fetchFileManagement = async () => {
        const fileManagement = await getFileManagement(
            selectedBooking.booking_handler.purchases_providers[0].id
        );
        console.log("fileManagement remove booking", fileManagement);
        setFileManagement(fileManagement);
    };

    const handleRemoveFile = async () => {
        console.log("removing file")
        await removeFile(fileManagement.id, fileType)
        await fetchFileManagement()
        setOpenRemoveFileDialog(false)
    }

    const Loading = () => (
        <CircularProgress size={24} sx={{ color: 'primary.main', position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px', }} />
    )

    return (
        <Dialog
            open={openRemoveFileDialog}
            onClose={handleClose}
        >
            <DialogTitle>
                Remove
            </DialogTitle>
            <DialogContent>
                Are you sure you want to delete the file?
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={handleRemoveFile} disabled={loading} autoFocus>
                    delete {fileType === "tickets" ? "Ticket" : "Bill"}
                    {
                        loading && <Loading />
                    }
                </Button>
            </DialogActions>

        </Dialog>
    )
}