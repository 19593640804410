import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useBookingStore from "../store/bookings";
import { useEffect, useState } from "react";

const TicketsDetailsRender = (fileManagement: any) => {
  const [urlTicketsSubString, setUrlTicketsSubString] = useState("");
  const { selectedBooking, setSnackBar } = useBookingStore((state) => ({
    selectedBooking: state.selectedBooking,
    setSnackBar: state.setSnackBar,
  }));

  const { items } = selectedBooking.cart;
  const { options } = items[0];

  let visit_date = "";
  var entryDateObject = options.find(
    (option: any) => option.name === "EntryDate"
  );
  if (entryDateObject) {
    visit_date = entryDateObject.value;
  }

  useEffect(() => {
    // I only want to get this "https://ticketing.colosseo.it/tickets_download?paymentCode=MCOLN2Q294XMARPD&format=raw" from the fileManagement object "url_tickets": '"{\"https://ticketing.colosseo.it/tickets_download?paymentCode=MCOLN2Q294XMARPD&format=raw\"}"'
    if (!fileManagement.fileManagement?.url_tickets) return;
    const urlTickets = fileManagement.fileManagement?.url_tickets;
    const urlTicketsString = urlTickets.substr(2, urlTickets.length - 4);

    setUrlTicketsSubString(urlTicketsString);
    console.log(urlTicketsSubString);
  }, []);

  const copyHandler = (e: any) => {
    navigator.clipboard.writeText(e.target.innerText);
    setSnackBar({
      open: true,
      message: "Copied to clipboard",
      severity: "info",
    });

    setTimeout(() => {
      setSnackBar({
        open: false,
        message: "Copied to clipboard",
        severity: "info",
      });
    }, 2000);
  };

  const renderParticipants = () => {
    const items = selectedBooking.cart.items;
    const optionsToRender = [];
    for (const item of items) {
      const itemOptions = item.options;
      const participantData = itemOptions.find(
        (opt: any) => opt.name === "ParticipantData"
      );
      if (participantData) {
        optionsToRender.push(participantData);
      }
    }
    if (optionsToRender.length > 0) {
      return optionsToRender.map((option: any, i: number) => {
        console.log(option.value);
        const participantData = JSON.parse(option.value);
        //console.log(participantData)
        const participantsRows = participantData.map(
          (participantData: any, i: number) => {
            return (
              <TableRow key={i}>
                <TableCell>{participantData.participant}</TableCell>
                <TableCell onClick={copyHandler} style={{ cursor: "pointer" }}>
                  {participantData.firstName}
                </TableCell>
                <TableCell onClick={copyHandler} style={{ cursor: "pointer" }}>
                  {participantData.lastName}
                </TableCell>
              </TableRow>
            );
          }
        );
        return (
          <>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  Participants
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{participantsRows}</TableBody>
          </>
        );
      });
    }
  };

  const renderLanguageAndTowers = () => {
    const aliasLanguages: { [x: string]: string } = {
      en: "Inglés",
      es: "Español",
      fr: "Francés",
      it: "Italiano",
      de: "Alemán",
      ca: "Catalán",
    };
    const optionsToRender = [];
    const items = selectedBooking.cart.items;
    //console.log(items);
    const language = items[0].options.find(
      (opt: any) => opt.name === "Language"
    );
    //console.log(language);
    if (language) optionsToRender.push(language);
    const tower = items[0].options.find((opt: any) => opt.name === "Tower");
    if (tower) optionsToRender.push(tower);
    //console.log(tower);
    // for (const item of items) {
    //   const itemOptions = item.options;
    //   const optionData = itemOptions.find(
    //     (opt: any) => opt.name === "Language" || opt.name === "Towers"
    //   );
    //   if (optionData) {
    //     optionsToRender.push(optionData);
    //   }
    // }
    if (optionsToRender.length === 0) {
      return null;
    }
    const rowLanguage = (
      <TableRow>
        <TableCell colSpan={2}>Language</TableCell>
        <TableCell onClick={copyHandler} style={{ cursor: "pointer" }}>
          {aliasLanguages[language?.value] ?? language?.value}
        </TableCell>
      </TableRow>
    );
    const rowTower = (
      <TableRow>
        <TableCell colSpan={2}>Towers</TableCell>
        <TableCell onClick={copyHandler} style={{ cursor: "pointer" }}>
          {tower?.value}
        </TableCell>
      </TableRow>
    );
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="center">
              Booking Options
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {language && rowLanguage}
          {tower && rowTower}
        </TableBody>
      </>
    );
  };

  return (
    <TableContainer sx={{ width: "80%", maxHeight: 650 }}>
      <Table>
        {renderLanguageAndTowers()}
        {renderParticipants()}
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="center">
              Tickets
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Visit Date</TableCell>
            <TableCell
              colSpan={2}
              onClick={copyHandler}
              style={{ cursor: "pointer" }}
            >
              {visit_date}
            </TableCell>
          </TableRow>
        </TableBody>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="center">
              Purchased
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item: any, i: any) => {
            return (
              <TableRow key={i}>
                {/* split the activity name by the space and get the last value */}
                <TableCell>
                  {
                    item.variant.name.split(" ")[
                      item.variant.name.split(" ").length - 1
                    ]
                  }
                </TableCell>
                <TableCell colSpan={2}>{item.quantity}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        {fileManagement.fileManagement?.activity_name === "ROM - COLO - ET" && (
          <>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  URL Tickets
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={3}
                  onClick={copyHandler}
                  style={{ cursor: "pointer" }}
                >
                  {urlTicketsSubString ? (
                    <a
                      href={urlTicketsSubString}
                      target="_blank"
                      style={{ color: "#0e0e0e;" }}
                    >
                      {urlTicketsSubString}
                    </a>
                  ) : (
                    "No URL Tickets"
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default TicketsDetailsRender;
